@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
   font-family: "Lexend";
   src: url(./fonts/Lexend-Thin.woff) format("woff");
   /* other formats include: 'woff2', 'truetype, 'opentype',
                                  'embedded-opentype', and 'svg' */
   font-style: normal;
   font-weight: 100;
}

@font-face {
   font-family: "Lexend";
   src: url(./fonts/Lexend-ExtraLight.woff) format("woff");
   /* other formats include: 'woff2', 'truetype, 'opentype',
                                  'embedded-opentype', and 'svg' */
   font-style: normal;
   font-weight: 200;
}

@font-face {
   font-family: "Lexend";
   src: url(./fonts/Lexend-Regular.woff) format("woff");
   /* other formats include: 'woff2', 'truetype, 'opentype',
                                  'embedded-opentype', and 'svg' */
   font-style: normal;
   font-weight: 400;
}
@font-face {
   font-family: "Lexend";
   src: url(./fonts/Lexend-Medium.woff) format("woff");
   /* other formats include: 'woff2', 'truetype, 'opentype',
                                  'embedded-opentype', and 'svg' */
   font-style: normal;
   font-weight: 500;
}
@font-face {
   font-family: "Lexend";
   src: url(./fonts/Lexend-SemiBold.woff) format("woff");
   /* other formats include: 'woff2', 'truetype, 'opentype',
                                  'embedded-opentype', and 'svg' */
   font-style: normal;
   font-weight: 600;
}

@font-face {
   font-family: "Lexend";
   src: url(./fonts/Lexend-Bold.woff) format("woff");
   /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
   font-style: normal;
   font-weight: 700;
}

@font-face {
   font-family: "Lexend";
   src: url(./fonts/Lexend-ExtraBold.woff) format("woff");
   /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
   font-style: normal;
   font-weight: 800;
}

@font-face {
   font-family: "Lexend";
   src: url(./fonts/Lexend-Black.woff) format("woff");
   /* other formats include: 'woff2', 'truetype, 'opentype',
                               'embedded-opentype', and 'svg' */
   font-style: normal;
   font-weight: 900;
}
